import React from 'react';
import logo from './logo.svg';
import './App.css';
import Barometer from "./Components/barometer";

function App() {
  return (
    <div className="App">
        <h1> Smallstreet Drukte Barometer</h1>

        <Barometer></Barometer>
    </div>
  );
}

export default App;
