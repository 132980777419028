import React, {Fragment, useEffect, useState} from 'react'

import ReactStoreIndicator from 'react-score-indicator'

const colors = [
    '#3da940',
    '#3da940',
    '#3da940',
    '#53b83a',
    '#84c42b',
    '#f1bc00',
    '#ed8d00',
    '#d12000',
];

const Barometer = () => {
    const [current, setCurrent] = useState(0);
    const [max, setMax] = useState(0);
    const [message, setMessage] = useState("");

    const updateBarometer = (newCurrent, newMax, newMessage) =>{
        setCurrent(newCurrent);
        setMax(newMax);
        setMessage(newMessage)
    };

    useEffect(() => {
        fetch('https://smallstreetbarometer.azurewebsites.net/home/raw')
            .then(response => response.json())
            .then(({maxCapacity, currentCapacity, message}) => updateBarometer(currentCapacity, maxCapacity, message));
    }, []);
        return (
            <Fragment>
            <ReactStoreIndicator
                value={current}
                maxValue={max}
                stepsColors={colors}
            />
            <p>{message}</p>
            </Fragment>
        )
    };

export default Barometer;
